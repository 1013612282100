@use "@functions/theme.scss";
@use "@variables/colors.scss";
@use "sass:color";

.root {
  align-items: center;
  background-color: colors.$white;
  border-radius: 26px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: colors.$text-primary;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: theme.spacing();
  user-select: "none";
  transition: box-shadow ease 0.2s;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

.googleIcon {
  flex-grow: 0;
  padding: theme.spacing();
  border-radius: 26px;

  &__svg {
    display: block;
    height: 18px;
    width: 18px;
  }
}

.btnText {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.2;
  padding-right: 15px;
}

.small {
  padding: 0;

  & .googleIcon {
    margin: theme.spacing(0.5);
    padding: theme.spacing(0.5);
  }

  & .btnText {
    font-size: 14px;
  }

  &.dark .btnText {
    padding-left: theme.spacing(0.5);
  }
}

.dark {
  color: colors.$white;
  background-color: #4285f4;
  border: 1px solid transparent;
  max-width: max-content;

  &:hover {
    background-color: "#4285f4";
  }

  &:active {
    color: colors.$white;
    background-color: #3367d6;
  }

  & .btnText {
    padding-left: theme.spacing(1.5);
  }

  & .googleIcon {
    background-color: colors.$white;
  }
}

.disabled {
  color: colors.$grey-500;
  background-color: colors.$grey-300;
  pointer-events: "none";

  &:hover {
    color: colors.$grey-500;
    background-color: colors.$grey-300;
    box-shadow: "none";
  }

  &:active {
    color: colors.$grey-500;
    background-color: colors.$grey-300;
  }
}
