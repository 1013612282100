@use "@variables/breakpoints.scss";

@mixin up($size) {
  @if not map-has-key(breakpoints.$exports, $size) {
    @warn "'#{$size}' is not a valid size";
  }

  $breakpoint: map-get(breakpoints.$exports, $size);

  @media (min-width: #{$breakpoint}px) {
    @content;
  }
}

@mixin down($size) {
  @if not map-has-key(breakpoints.$exports, $size) {
    @warn "'#{$size}' is not a valid size";
  }

  $breakpoint: map-get(breakpoints.$exports, $size);

  @media (max-width: #{$breakpoint}px) {
    @content;
  }
}
